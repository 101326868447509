import {
	GQLExtensionContextsFilterType,
	type UseExtensionListOptions,
} from '@atlassian/forge-ui/provider';

import { getAGGClient } from '@confluence/graphql';

import { getWorkspaceARI } from './useWorkspaceARI';

export const getUseExtensionListOptions = ({
	cloudId,
	activationId,
	locale,
	dataClassificationTags,
	isAccessNarrowingFlagEnabled,
	expandAppOwner,
	moduleType,
	queryOptions,
	skip,
}) => {
	const workspaceARI = getWorkspaceARI({ cloudId, activationId });

	const options: UseExtensionListOptions = {
		client: getAGGClient(),
		contextIds: [workspaceARI],
		type: moduleType,
		queryOptions: {
			...queryOptions,
			skip,
		},
		expandAppOwner,
		locale,
	};

	// Passing the `filter` key will use the newer extension() resolver over the extensionByType() resolver
	// Going forward we to use the newer resolver
	// So remove this check when removing the flag: "confluence.frontend.ecosystem.access.narrrowing"
	if (isAccessNarrowingFlagEnabled) {
		options.filter = [{ type: GQLExtensionContextsFilterType.ExtensionType, value: [moduleType] }];
		options.dataClassificationTagIds = dataClassificationTags;
	}

	return options;
};
